import React from "react";
import { useHistory } from "react-router-dom";
import classes from "./index.module.scss";
import DualsLogo from "../../assets/images/duals-logo.svg";
import { BECOME_A_TUTOR_URL } from "../../helpers/URLs";
import NotFound from "../../assets/images/not-found.svg";
import Button from "../../components/Button";
import { ROUTE_PATHS } from "../../routes/RoutePaths";

const PageNotFound = () => {
	const history = useHistory();

	return (
		<div className={classes.__page_not_found}>
			<div className={classes.__header}>
				<div className={classes.__duals_logo}>
					<img src={DualsLogo} />
				</div>
				<div className={classes.__header_btn}>
					<button
						className={classes.__become_a_tutor_btn}
						onClick={() => window.open(BECOME_A_TUTOR_URL, "_blank")}
					>
						Become a Tutor
					</button>
				</div>
			</div>
			<div className={classes.__container}>
				<div className={classes.__not_found_img}>
					<img src={NotFound} />
				</div>
				<div className={classes.__content}>
					<h3 className={classes.__title}>
						Errrr... looks like you are lost 🤔
					</h3>
					<p className={classes.__description}>
						This page doesn't exist. Try another page or go back home.
					</p>
					<Button
						label="Go back home"
						style={{ width: 255, marginTop: 32 }}
						onClick={() => history.push(ROUTE_PATHS.home)}
					/>
				</div>
			</div>
		</div>
	);
};

export default PageNotFound;
