import React from "react";
import { ROUTE_PATHS } from "./RoutePaths";

const Home = React.lazy(() => import("../pages/Home"));
const PrivacyPolicy = React.lazy(() => import("../pages/PrivacyPolicy"));
const Contribute = React.lazy(() => import("../pages/Contribute"));
const ThankYou = React.lazy(() => import("../pages/ThankYou"));
const FellowShip = React.lazy(() => import("../pages/FellowShip"));
const TermsAndConditions = React.lazy(() =>
  import("../pages/TermsAndConditions")
);
const About = React.lazy(() => import("../pages/About"));
const CommunityGuidelines = React.lazy(() =>
  import("../pages/CommunityGuidelines")
);
const I18Next = React.lazy(() => import("../pages/I18Next"));
const Invitation = React.lazy(() => import("../pages/Invitation"));
const Invitation1 = React.lazy(() => import("../pages/Invitation1"));
const Invitation2 = React.lazy(() => import("../pages/Invitation2"));
const Invitation3 = React.lazy(() => import("../pages/Invitation3"));
const NFT = React.lazy(() => import("../pages/NFT"));
const Download = React.lazy(() => import("../pages/Download"));
const LearnEnglish1 = React.lazy(() => import("../pages/LearnEnglish1"));
const LearnEnglish2 = React.lazy(() => import("../pages/LearnEnglish2"));

const OpenRoutes = [
  {
    path: ROUTE_PATHS.home,
    exact: true,
    name: "Home",
    component: Home,
  },
  {
    path: ROUTE_PATHS.privacyPolicy,
    exact: true,
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: ROUTE_PATHS.termsAndConditions,
    exact: true,
    name: "TermsAndConditions",
    component: TermsAndConditions,
  },
  {
    path: ROUTE_PATHS.about,
    exact: true,
    name: "About",
    component: About,
  },
  {
    path: ROUTE_PATHS.communityGuidelines,
    exact: true,
    name: "CommunityGuidelines",
    component: CommunityGuidelines,
  },
  {
    path: ROUTE_PATHS.contribute,
    exact: true,
    name: "Contribute",
    component: Contribute,
  },
  // {
  // 	path: ROUTE_PATHS.thankYou,
  // 	exact: true,
  // 	name: "ThankYou",
  // 	component: ThankYou,
  // },,
  // {
  // 	path: ROUTE_PATHS.fellowShip,
  // 	exact: true,
  // 	name: "FellowShip",
  // 	component: FellowShip,
  // },
  {
    path: ROUTE_PATHS.thankYou,
    exact: true,
    name: "ThankYou",
    component: ThankYou,
  },
  ,
  {
    path: ROUTE_PATHS.fellowShip,
    exact: true,
    name: "FellowShip",
    component: FellowShip,
  },
  {
    path: ROUTE_PATHS.i18next,
    exact: true,
    name: "I18Next",
    component: I18Next,
  },
  {
    path: ROUTE_PATHS.invitation,
    exact: true,
    name: "Invitation",
    component: Invitation,
  },
  {
    path: ROUTE_PATHS.invitation1,
    exact: true,
    name: "Invitation",
    component: Invitation1,
  },
  {
    path: ROUTE_PATHS.invitation2,
    exact: true,
    name: "Invitation",
    component: Invitation2,
  },
  {
    path: ROUTE_PATHS.invitation3,
    exact: true,
    name: "Invitation",
    component: Invitation3,
  },
  {
    path: ROUTE_PATHS.nft,
    exact: true,
    name: "NFT",
    component: NFT,
  },
  {
    path: ROUTE_PATHS.download,
    exact: true,
    name: "Download",
    component: Download,
  },
  {
    path: ROUTE_PATHS.learnEnglish1,
    exact: true,
    name: "LearnEnglish1",
    component: LearnEnglish1,
  },
  {
    path: ROUTE_PATHS.learnEnglish2,
    exact: true,
    name: "LearnEnglish2",
    component: LearnEnglish2,
  },
];

export { OpenRoutes };
