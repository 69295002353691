export const ROUTE_PATHS = {
  home: "/",
  privacyPolicy: "/privacy",
  termsAndConditions: "/terms",
  about: "/about",
  communityGuidelines: "/community-guidelines",
  contribute: "/contribute",
  // thankYou: "/thank-you",
  // fellowShip: "/fellowship",
  thankYou: "/thank-you",
  fellowShip: "/fellowship",
  i18next: "/i18next",
  invitation: "/invitation",
  invitation1: "/invitation1",
  invitation2: "/invitation2",
  invitation3: "/invitation3",
  nft: "/nft",
  download: "/download",
  learnEnglish1: "/learn-english-1",
  learnEnglish2: "/learn-english-2",
};
