import React from "react";
import classes from "./index.module.scss";

const Button = (props) => {
	const { label = "", onClick = () => {}, style, className = "", dataFor = "" } = props || {};
	return (
		<button
			className={`${classes.__button} ${className}`}
			style={style}
			onClick={onClick}
			data-for={dataFor}
			data-tip
		>
			{label}
		</button>
	);
};

export default Button;
