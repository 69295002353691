import React from "react";
import classes from "./index.module.scss";
import AppStyles from "../../App.module.scss";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import DualsLogo from "../../assets/images/duals-logo.svg";
import AppStore from "../../assets/images/appstore.svg";
import PlayStore from "../../assets/images/playstore.svg";
import TopArrow from "../../assets/images/top-arrow.svg";
import FacebookIcon from "../../assets/images/facebook-icon.svg";
import InstagramIcon from "../../assets/images/instagram-icon.svg";
import LinkedinIcon from "../../assets/images/linkedin-icon.svg";
import TwitterIcon from "../../assets/images/twitter-icon.svg";
import YoutubeIcon from "../../assets/images/youtube-icon.svg";
import TiktokIcon from "../../assets/images/tiktok.svg";
import FacebookIconHover from "../../assets/images/facebook-hover.svg";
import InstagramIconHover from "../../assets/images/instagram-hover.svg";
import LinkedinIconHover from "../../assets/images/linkedin-hover.svg";
import TwitterIconHover from "../../assets/images/twitter-hover.svg";
import YoutubeIconHover from "../../assets/images/youtube-hover.svg";
import TiktokIconHover from "../../assets/images/tiktok-hover.svg";
import { ROUTE_PATHS } from "../../routes/RoutePaths";
import ScrollToTop from "../../helpers/ScrollToTop";
import {
  APP_STORE_URL,
  BECOME_A_TUTOR_FAQ,
  BLOG_URL,
  FACEBOOK_URL,
  FEAUTRE_REQUEST_URL,
  HELPS_URL,
  INSTAGRAM_URL,
  LINKEDIN_URL,
  LOGIN_URL,
  PLAY_STORE_URL,
  TIKTOK_URL,
  TWITTER_URL,
  YOUTUBE_URL,
} from "../../helpers/URLs";

const Footer = () => {
  const history = useHistory();
  const isMobile = useMediaQuery({ query: "(max-width: 428px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 768px)" });
  const isBigScreenTablet = useMediaQuery({ query: "(max-width: 1024px)" });

  const mobileFooter = () => {
    return (
      <div className={classes.__footer}>
        <div className={AppStyles.view_container}>
          <div className={classes.__container}>
            <div className={classes.__row}>
              <div className={classes.__column}>
                <div className={classes.__heading}>Product</div>
                <div
                  className={classes.__links}
                  onClick={() => {
                    ScrollToTop();
                    history.push(ROUTE_PATHS.home);
                  }}
                >
                  Home
                </div>
                <div
                  className={classes.__links}
                  onClick={() => {
                    ScrollToTop();
                    history.push(ROUTE_PATHS.about);
                  }}
                >
                  About
                </div>
                <div
                  className={classes.__links}
                  onClick={() => window.open(LOGIN_URL, "_blank")}
                >
                  Log In
                </div>
                <div
                  className={classes.__links}
                  onClick={() => window.open(BECOME_A_TUTOR_FAQ, "_blank")}
                >
                  Become a Tutor
                </div>
              </div>
              <div className={classes.__column}>
                <div className={classes.__heading}>Community</div>
                <div
                  className={classes.__links}
                  onClick={() => window.open(BLOG_URL, "_blank")}
                >
                  Blog
                </div>
                <div
                  className={classes.__links}
                  onClick={() => {
                    ScrollToTop();
                    history.push(ROUTE_PATHS.communityGuidelines);
                  }}
                >
                  Community Guidelines
                </div>
                <div
                  className={classes.__links}
                  onClick={() => window.open(HELPS_URL, "_blank")}
                >
                  Help
                </div>
                <div
                  className={classes.__links}
                  onClick={() => window.open(FEAUTRE_REQUEST_URL, "_blank")}
                >
                  Feature Request
                </div>
              </div>
            </div>
            <div className={classes.__row}>
              <div className={classes.__column}>
                <div className={classes.__heading}>Legal</div>
                <div
                  className={classes.__links}
                  onClick={() => {
                    ScrollToTop();
                    history.push(ROUTE_PATHS.privacyPolicy);
                  }}
                >
                  Privacy Policy
                </div>
                <div
                  className={classes.__links}
                  onClick={() => {
                    ScrollToTop();
                    history.push(ROUTE_PATHS.termsAndConditions);
                  }}
                >
                  Terms & Conditions
                </div>
              </div>
              <div className={`${classes.__column} ${classes.__social_links}`}>
                <div className={classes.__heading}>Follow Us</div>
                <a href={FACEBOOK_URL} target="_blank">
                  <img src={FacebookIcon} width="30" height="30" />
                </a>
                <a href={INSTAGRAM_URL} target="_blank">
                  <img src={InstagramIcon} width="30" height="30" />
                </a>
                <a href={LINKEDIN_URL} target="_blank">
                  <img src={LinkedinIcon} width="30" height="30" />
                </a>
                <br />
                <a href={TWITTER_URL} target="_blank">
                  <img src={TwitterIcon} width="30" height="30" />
                </a>
                <a href={YOUTUBE_URL} target="_blank">
                  <img src={YoutubeIcon} width="30" height="30" />
                </a>
                <a href={TIKTOK_URL} target="_blank">
                  <img src={TiktokIcon} width="30" height="30" />
                </a>
              </div>
            </div>
            <div className={classes.__row}>
              <div className={classes.__column}>
                <img
                  className={classes.__logo}
                  src={DualsLogo}
                  width="116"
                  height="30"
                  onClick={() => {
                    ScrollToTop();
                    history.push(ROUTE_PATHS.home);
                  }}
                />
              </div>
            </div>
            <div className={classes.__row}>
              <div className={`${classes.__column} ${classes.__store_col}`}>
                <div>
                  <a href={APP_STORE_URL} target="_blank">
                    <img src={AppStore} />
                  </a>
                </div>
                <div>
                  <a href={PLAY_STORE_URL} target="_blank">
                    <img
                      src={PlayStore}
                      className={classes.__play_store_icon}
                    />
                  </a>
                </div>
              </div>
              <div className={`${classes.__column} ${classes.__arrow}`}>
                <img
                  src={TopArrow}
                  onClick={() => ScrollToTop()}
                  width="40"
                  height="40"
                />
              </div>
            </div>
            <div className={`${classes.__row} ${classes.__second_row}`}>
              <div className={classes.__column}>
                <div className={classes.__copy_rights}>
                  © Duals 2022 All Rights Reserved
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const desktopFooter = () => {
    return (
      <div className={classes.__footer}>
        <div className={AppStyles.view_container}>
          <div className={classes.__container}>
            <div className={`${classes.__row} ${classes.__first_row}`}>
              <div className={`${classes.__column} ${classes.__first_column}`}>
                <img
                  className={classes.__logo}
                  src={DualsLogo}
                  width="116"
                  height="30"
                  onClick={() => {
                    ScrollToTop();
                    history.push(ROUTE_PATHS.home);
                  }}
                />
                <div className={classes.__app_store}>
                  <a href={APP_STORE_URL} target="_blank">
                    <img src={AppStore} className={classes.__store_img} />
                  </a>
                </div>
                <div>
                  <a href={PLAY_STORE_URL} target="_blank">
                    <img src={PlayStore} className={classes.__store_img} />
                  </a>
                </div>
              </div>
              <div className={`${classes.__column} ${classes.__second_column}`}>
                <div className={classes.__heading}>Product</div>
                <div
                  className={classes.__links}
                  onClick={() => {
                    ScrollToTop();
                    history.push(ROUTE_PATHS.home);
                  }}
                >
                  Home
                </div>
                <div
                  className={classes.__links}
                  onClick={() => {
                    ScrollToTop();
                    history.push(ROUTE_PATHS.about);
                  }}
                >
                  About
                </div>
                <div
                  className={classes.__links}
                  onClick={() => window.open(LOGIN_URL, "_blank")}
                >
                  Log In
                </div>
                <div
                  className={classes.__links}
                  onClick={() => window.open(BECOME_A_TUTOR_FAQ, "_blank")}
                >
                  Become a Tutor
                </div>
                <div
                  className={classes.__links}
                  onClick={() => {
                    ScrollToTop();
                    history.push(ROUTE_PATHS.contribute);
                  }}
                >
                  Become a Contributor
                </div>
              </div>
              <div className={classes.__column}>
                <div className={classes.__heading}>Community</div>
                <div
                  className={classes.__links}
                  onClick={() => window.open(BLOG_URL, "_blank")}
                >
                  Blog
                </div>
                <div
                  className={classes.__links}
                  onClick={() => {
                    ScrollToTop();
                    history.push(ROUTE_PATHS.communityGuidelines);
                  }}
                >
                  Community Guidelines
                </div>
                <div
                  className={classes.__links}
                  onClick={() => window.open(HELPS_URL, "_blank")}
                >
                  Help
                </div>
                <div
                  className={classes.__links}
                  onClick={() => window.open(FEAUTRE_REQUEST_URL, "_blank")}
                >
                  Feature Request
                </div>
              </div>
              <div className={classes.__column}>
                <div className={classes.__heading}>Legal</div>
                <div
                  className={classes.__links}
                  onClick={() => {
                    ScrollToTop();
                    history.push(ROUTE_PATHS.privacyPolicy);
                  }}
                >
                  Privacy Policy
                </div>
                <div
                  className={classes.__links}
                  onClick={() => {
                    ScrollToTop();
                    history.push(ROUTE_PATHS.termsAndConditions);
                  }}
                >
                  Terms & Conditions
                </div>
              </div>
              <div>
                <img
                  src={TopArrow}
                  width="40"
                  height="40"
                  onClick={() => ScrollToTop()}
                  className={classes.__top_arrow}
                />
              </div>
            </div>
            <div className={`${classes.__row} ${classes.__second_row}`}>
              <div className={classes.__column}>
                <div className={classes.__copy_rights}>
                  © Duals 2022 All Rights Reserved
                </div>
              </div>
              <div
                className={`${classes.__column} ${classes.__social_icons} flex items-center justify-end`}
              >
                <a href={FACEBOOK_URL} target="_blank">
                  <img
                    src={FacebookIcon}
                    width="30"
                    height="30"
                    onMouseOver={(e) =>
                      (e.currentTarget.src = FacebookIconHover)
                    }
                    onMouseOut={(e) => (e.currentTarget.src = FacebookIcon)}
                  />
                </a>
                <a href={INSTAGRAM_URL} target="_blank">
                  <img
                    src={InstagramIcon}
                    width="30"
                    height="30"
                    onMouseOver={(e) =>
                      (e.currentTarget.src = InstagramIconHover)
                    }
                    onMouseOut={(e) => (e.currentTarget.src = InstagramIcon)}
                  />
                </a>
                <a href={LINKEDIN_URL} target="_blank">
                  <img
                    src={LinkedinIcon}
                    width="30"
                    height="30"
                    onMouseOver={(e) =>
                      (e.currentTarget.src = LinkedinIconHover)
                    }
                    onMouseOut={(e) => (e.currentTarget.src = LinkedinIcon)}
                  />
                </a>
                <a href={TWITTER_URL} target="_blank">
                  <img
                    src={TwitterIcon}
                    width="30"
                    height="30"
                    onMouseOver={(e) =>
                      (e.currentTarget.src = TwitterIconHover)
                    }
                    onMouseOut={(e) => (e.currentTarget.src = TwitterIcon)}
                  />
                </a>
                <a href={YOUTUBE_URL} target="_blank">
                  <img
                    src={YoutubeIcon}
                    width="30"
                    height="30"
                    onMouseOver={(e) =>
                      (e.currentTarget.src = YoutubeIconHover)
                    }
                    onMouseOut={(e) => (e.currentTarget.src = YoutubeIcon)}
                  />
                </a>
                <a href={TIKTOK_URL} target="_blank">
                  <img
                    src={TiktokIcon}
                    width="30"
                    height="30"
                    onMouseOver={(e) => (e.currentTarget.src = TiktokIconHover)}
                    onMouseOut={(e) => (e.currentTarget.src = TiktokIcon)}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return isMobile
    ? mobileFooter()
    : isTablet
    ? mobileFooter()
    : isBigScreenTablet
    ? mobileFooter()
    : desktopFooter();
};

export default Footer;
