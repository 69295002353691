import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import smoothscroll from "smoothscroll-polyfill";
import Footer from "./components/Footer";
import DualsLogo from "./assets/images/duals.svg";
import PageNotFound from "./pages/PageNotFound";
import { OpenRoutes } from "./routes/Routes";
import AppStyles from "./App.module.scss";

function App() {
	let Routes = OpenRoutes;
	const [blockLoading, setBlockLoadingState] = useState(true);
	smoothscroll.polyfill();

	window.addEventListener('load', function () {
		setBlockLoadingState(false)
	})

	return (
		<Router>
			<div className={AppStyles.appWrapper}>
				{(() => {
					if (blockLoading) {
						return (
							<div className={AppStyles.block_loading}>
								<div className={AppStyles.heroXY}>
									<img className={AppStyles.animateFlicker} src={DualsLogo} />
								</div>
							</div>
						)
					}
				})()}
				<div
					style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
				>
					<React.Suspense fallback={() => <span></span>}>
						<Switch>
							{Routes.map((route, index) => {
								return (
									<Route
										key={index}
										path={route.path}
										exact={route.exact}
										name={route.name}
										render={(props) => <route.component {...props} />}
									/>
								);
							})}
							<Route path="" component={PageNotFound} />
						</Switch>
					</React.Suspense>
					<Footer />
				</div>
			</div>
		</Router>
	);
}

export default App;
