export const BASE_URL = "http://localhost:3000";
export const PLAY_STORE_URL =
  "https://play.google.com/store/apps/details?id=app.duals";
export const APP_STORE_URL = "https://apps.apple.com/us/app/duals/id1544795913";
export const FACEBOOK_URL = "https://facebook.com/dualsapp";
export const INSTAGRAM_URL = "https://instagram.com/dualsapp";
export const LINKEDIN_URL = "https://www.linkedin.com/company/dualsapp/";
export const TWITTER_URL = "https://twitter.com/dualsapp";
export const YOUTUBE_URL =
  "https://www.youtube.com/channel/UCJsfZRi8g_wN3LAdd70d5Ng";
export const TIKTOK_URL = "https://www.tiktok.com/@dualsapp";
export const LOGIN_URL = "https://my.duals.app";
export const HELPS_URL = "https://help.duals.app/";
export const FEAUTRE_REQUEST_URL = "https://help.duals.app/";
export const BECOME_A_TUTOR_URL = "https://forms.gle/kDtcMSLhgsiyDJMY8";
export const COOKIE_URL = "https://www.duals.app/privacy";
export const DUALS_APP_URL = "https://go.duals.app/download";
export const BLOG_URL = "https://blog.duals.app/";
export const BECOME_A_TUTOR_FAQ =
  "https://help.duals.app/kb/en/article/how-can-i-become-a-duals-tutor";
export const DISCORD_URL = "https://discord.gg/uan9VnnfuN";
